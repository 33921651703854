import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';

import Banners from '@components/header/Banners';

import * as styles from '@styles/modules/nav.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Demo"}) {
      ...Header
    }
  }`);
  const {
    headerLogo,
    mobileHeaderLogo
  } = data?.contentfulLayout;
  return (
    <>
      <Banners data={data} />
      <header>
        <Container>
          <nav id={`mainNav`} className={clsx(styles.navHeaderContainer)}>
            <div className='d-flex flex-row justify-content-center align-items-center'>
              <div className={`d-flex flex-row`}>
                {headerLogo &&
                  <Link to={`/`} className={clsx(`d-flex align-items-center`, styles.imgLogo)} title='Homepage'>
                    <Img image={headerLogo?.gatsbyImageData} file={headerLogo?.file} className={`d-none d-xl-block`}/>
                    <Img image={mobileHeaderLogo?.gatsbyImageData} file={mobileHeaderLogo?.file} className={`d-xl-none`}/>
                  </Link>
                }
              </div>
            </div>
          </nav>
        </Container>
      </header>
    </>
  )
}

export default Header
