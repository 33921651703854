import React, { useState, useEffect } from 'react';

import Query from '@queries/quiz/StepThree';
import { saveUser } from '@redux/user/actions';
import { quizFormNextStep, quizFormPrevStep } from '@redux/quiz/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StaticImage } from 'gatsby-plugin-image';

import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';

import Heading from '@common/Heading';
import Row from '@common/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const states = [
  {key: 'Outside of United States & Canada', value: 'International'},
  {key: 'Alabama', value: 'Alabama'},
  {key: 'Alaska', value: 'Alaska'},
  {key: 'American Samoa', value: 'American Samoa'},
  {key: 'Arizona', value: 'Arizona'},
  {key: 'Arkansas', value: 'Arkansas'},
  {key: 'California', value: 'California'},
  {key: 'Colorado', value: 'Colorado'},
  {key: 'Connecticut', value: 'Connecticut'},
  {key: 'Delaware', value: 'Delaware'},
  {key: 'District of Columbia', value: 'District of Columbia'},
  {key: 'Florida', value: 'Florida'},
  {key: 'Georgia', value: 'Georgia'},
  {key: 'Guam', value: 'Guam'},
  {key: 'Hawaii', value: 'Hawaii'},
  {key: 'Idaho', value: 'Idaho'},
  {key: 'Illinois', value: 'Illinois'},
  {key: 'Indiana', value: 'Indiana'},
  {key: 'Iowa', value: 'Iowa'},
  {key: 'Kansas', value: 'Kansas'},
  {key: 'Kentucky', value: 'Kentucky'},
  {key: 'Louisiana', value: 'Louisiana'},
  {key: 'Maine', value: 'Maine'},
  {key: 'Maryland', value: 'Maryland'},
  {key: 'Massachusetts', value: 'Massachusetts'},
  {key: 'Michigan', value: 'Michigan'},
  {key: 'Minnesota', value: 'Minnesota'},
  {key: 'Mississippi', value: 'Mississippi'},
  {key: 'Missouri', value: 'Missouri'},
  {key: 'Montana', value: 'Montana'},
  {key: 'Nebraska', value: 'Nebraska'},
  {key: 'Nevada', value: 'Nevada'},
  {key: 'New Hampshire', value: 'New Hampshire'},
  {key: 'New Jersey', value: 'New Jersey'},
  {key: 'New Mexico', value: 'New Mexico'},
  {key: 'New York', value: 'New York'},
  {key: 'North Carolina', value: 'North Carolina'},
  {key: 'North Dakota', value: 'North Dakota'},
  {key: 'North Carolina', value: 'North Carolina'},
  {key: 'Northern Mariana Islands', value: 'Northern Mariana Islands'},
  {key: 'Ohio', value: 'Ohio'},
  {key: 'Oklahoma', value: 'Oklahoma'},
  {key: 'Oregon', value: 'Oregon'},
  {key: 'Pennsylvania', value: 'Pennsylvania'},
  {key: 'Puerto Rico', value: 'Puerto Rico'},
  {key: 'Rhode Island', value: 'Rhode Island'},
  {key: 'South Carolina', value: 'South Carolina'},
  {key: 'South Dakota', value: 'South Dakota'},
  {key: 'Tennessee', value: 'Tennessee'},
  {key: 'Texas', value: 'Texas'},
  {key: 'Utah', value: 'Utah'},
  {key: 'Vermont', value: 'Vermont'},
  {key: 'Virgin Islands', value: 'Virgin Islands'},
  {key: 'Virginia', value: 'Virginia'},
  {key: 'Washington', value: 'Washington'},
  {key: 'West Virginia', value: 'West Virginia'},
  {key: 'Wisconsin', value: 'Wisconsin'},
  {key: 'Wyoming', value: 'Wyoming'},
  {key: 'Alberta', value: 'Alberta'},
  {key: 'British Columbia', value: 'British Columbia'},
  {key: 'Manitoba', value: 'Manitoba'},
  {key: 'New Brunswick', value: 'New Brunswick'},
  {key: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador'},
  {key: 'Northwest Territories', value: 'Northwest Territories'},
  {key: 'Nova Scotia', value: 'Nova Scotia'},
  {key: 'Nunavut', value: 'Nunavut'},
  {key: 'Ontario', value: 'Ontario'},
  {key: 'Prince Edward Island', value: 'Prince Edward Island'},
  {key: 'Quebec', value: 'Quebec'},
  {key: 'Saskatchewan', value: 'Saskatchewan'},
  {key: 'Yukon', value: 'Yukon'}
];

const StepThree = ({ nextStep, previousStep }) => {
  const data = Query();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {
    centerName,
    enrollmentRangeLabel,
    enrollmentRangeList,
    centerEnrolmentLabel,
    centerEnrolmentList
  } = data.contentfulFormFields;
  const [showCenterEnrolmentField, setShowCenterEnrolmentField] = useState(false);
  const { register, formState: { errors, isValid }, handleSubmit } = useForm({
    defaultValues: {
      centerName: user.centerName,
      enrollmentRange: user.enrollmentRange,
      centerEnrolment: user.centerEnrolment,
      stateProvince: user.stateProvince,
      currentSolution: user.currentSolution
    }
  });
  const onSubmit = (data) => {
    if (isValid) {
      dispatch(saveUser(data));
      dispatch(quizFormNextStep());
      nextStep()
    }
  };
  const handlePrevStep = () => {
    dispatch(quizFormPrevStep());
    previousStep();
  }
  useEffect(() => {
    const isHomeDaycare = user.title === 'Home Daycare Provider';
    setShowCenterEnrolmentField(isHomeDaycare);
  }, [user]);
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className='demo-quiz-form'>
      <Heading type='h2' className='fs-32 mb-3'>{'Let\'s learn more about your center!'}</Heading>
      <Row>
        <div className='col-lg-7'>
          <Form.Group controlId='centerName' className='mb-3 required'>
            <Form.Label>{centerName}</Form.Label>
            <Form.Control
              name='centerName'
              type='text'
              placeholder={centerName}            
              required
              isInvalid={!!errors.centerName}
              {...register('centerName', { required: 'Please type in your center name.' })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.centerName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {!showCenterEnrolmentField &&
            <Form.Group controlId='enrollmentRange' className='mb-3 required'>
              <Form.Label>{enrollmentRangeLabel}</Form.Label>
              <Form.Select
                name='enrollmentRange'
                required
                defaultValue={user.enrollmentRange}
                isInvalid={!!errors.enrollmentRange}
                {...register('enrollmentRange', { required: true })}
              >
                <option value=''>{enrollmentRangeLabel}</option>
                {enrollmentRangeList.map((size, index) => (
                  <option key={index} value={size}>{size}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>
                {errors.enrollmentRange?.message}
              </Form.Control.Feedback>
            </Form.Group>
          }
          {showCenterEnrolmentField &&
            <Form.Group controlId='centerEnrolment' className='mb-3 required'>
              <Form.Label>{centerEnrolmentLabel}</Form.Label>
              <Form.Select
                name='centerEnrolment'
                required
                isInvalid={!!errors.centerEnrolment}
                {...register('centerEnrolment', { required: true })}
              >
                <option value=''>{centerEnrolmentLabel}</option>
                {centerEnrolmentList.map((size, index) => (
                  <option key={index} value={size}>{size}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>
                {errors.centerEnrolment?.message}
              </Form.Control.Feedback>
            </Form.Group>
          }
          <Form.Group controlId='stateProvince' className='mb-3 required'>
            <Form.Label>{'State/Province'}</Form.Label>
            <Form.Select
              name='stateProvince'
              required
              isInvalid={!!errors.stateProvince}
              {...register('stateProvince', { required: 'State/Province is required.' })}
            >
              <option value=''>{'State/Province'}</option>
              {states.map((state, index) => (
                <option key={index} value={state.value}>{state.key}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
              {errors.stateProvince?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='currentSolution' className='mb-3 required'>
            <Form.Label>{'Do you currently use a digital childcare management platform?'}</Form.Label>
            <Form.Select
              name='currentSolution'
              required
              defaultValue={user.currentSolution}
              {...register('currentSolution', { required: 'Please select an option', validate: { doesNotEqualBlank: (value) => {
                return value !== '';
              }} })}
            >
              <option value=''>{'Yes/No'}</option>
              <option value={true}>{'Yes'}</option>
              <option value={false}>{'No'}</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
              {errors.currentSolution?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className='col'>
          <StaticImage src={`../../../../images/demo/lets_chat_blocks.png`} placeholder='none' alt='' />
        </div>
      </Row>
      <div className='d-flex justify-content-between'>
        <button type='button' onClick={handlePrevStep} className='btn shadow-none'>
          <FontAwesomeIcon icon={faArrowLeft} size='lg'/>
          <span className='sr-only sr-only-focusable'>Previous Step</span>
        </button>
        <button type='submit' className='btn btn-primary btn-lg btn-pink-bright'>{'Next'}</button>
      </div>
    </Form>
  )
}

export default StepThree
