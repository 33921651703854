import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Player } from '@lottiefiles/react-lottie-player';

const Image = ({
  file,
  image,
  alt,
  imgStyle,
  ...other
}) => {
  if (file?.contentType === 'image/svg+xml') {
    return <img src={file?.url} alt={alt != undefined ? alt : ''} width={file.details.image.width} height={file.details.image.height} aria-hidden={alt == undefined} {...other} style={imgStyle}/>
  }
  if (file?.contentType === 'application/json') {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={`${file?.url}`}
        style={{ display: 'contents', height: '100%', width: '100%' }}
      />
    )
  }
  return (
    <>
      {image && 
        <GatsbyImage image={image} alt={alt != undefined ? alt : ''} aria-hidden={alt == undefined} imgStyle={imgStyle} {...other} />
      }
    </>
  )
}

export default Image
